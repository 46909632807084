/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { ThemeProvider } from "./src/hooks/useTheme"
import { Layout } from "./src/components/layout"
import { IntlProvider } from "gatsby-plugin-intl"

import "./src/styles/globals.css"

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>
}

export const wrapPageElement = ({ element, props }) => {
  const intl = props.pageContext.intl
  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLocale}
      messages={intl.messages}
      onError={console.error}
    >
      <Layout {...props}>{element}</Layout>
    </IntlProvider>
  )
}

// export const onClientEntry = () => {
//   if (typeof window.plausible === "undefined") {
//     window.plausible = function () {
//       ;(window.plausible.q = window.plausible.q || []).push(arguments)
//     }
//   }
// }
