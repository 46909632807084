import React, { createContext, useContext, useEffect, useState } from "react"

export interface ThemeContextType {
  isDarkMode: boolean
  toggleTheme: () => void
}

// Crear el contexto del tema
const ThemeContext = createContext({})

// Crear un proveedor de tema
export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    // Obtener el tema actual de localStorage al cargar
    const currentTheme = localStorage.getItem("theme") || "light"
    setIsDarkMode(currentTheme === "dark")
    document.documentElement.setAttribute("data-theme", currentTheme)
  }, [])

  const toggleTheme = () => {
    const newTheme = isDarkMode ? "light" : "dark"
    setIsDarkMode(!isDarkMode)
    localStorage.setItem("theme", newTheme)
    document.documentElement.setAttribute("data-theme", newTheme)
  }

  return <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>{children}</ThemeContext.Provider>
}

// Custom hook para usar el tema
export const useTheme = () => useContext(ThemeContext) as ThemeContextType
