import React, { useMemo, useState } from "react"
import { useTheme } from "../hooks/useTheme"
import { StaticImage } from "gatsby-plugin-image"
import dayjs from "dayjs"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

export const Footer = () => {
  const { locale } = useIntl()
  const { isDarkMode } = useTheme()
  const intl = useIntl()
  const currentYear = useMemo(() => dayjs().year(), [])

  return (
    <>
      <section className={"bg-base-300"}>
        <div className="container mx-auto max-w-screen-xl px-4 py-14 flex items-center justify-center flex-wrap gap-x-12 gap-y-12">
          {isDarkMode && (
            <div className="w-96 max-w-full p-2 flex-shrink-0">
              <StaticImage src={"../../images/enisa-startup-dark.svg"} alt={"ENISA Startup Dark"} />
            </div>
          )}
          {!isDarkMode && (
            <div className="w-96 max-w-full p-2 flex-shrink-0">
              <StaticImage src={"../../images/enisa-startup-light.svg"} alt={"ENISA Startup Light"} />
            </div>
          )}

          <div className="w-36">
            <a
              href={"https://www.ccn-cert.cni.es/amparo/API/public/certificaciones/6570/download/4843"}
              target={"_blank"}
            >
              <StaticImage src={"../../images/ens.png"} alt={intl.formatMessage({ defaultMessage: "ENS" })} />
            </a>
          </div>
        </div>
      </section>

      <footer className={"bg-base-200"}>
        <div className="container text-base-content mx-auto max-w-screen-xl px-4 pt-14 pb-4 flex flex-col">
          <div className="flex flex-col lg:flex-row justify-between items-start space-y-6 lg:space-y-0">
            <aside>
              <div className={"flex flex-col gap-3"}>
                <div>
                  {!isDarkMode && (
                    <StaticImage height={35} src={`../../images/tramita-text-light.svg`} alt={`Tramita Software SL`} />
                  )}
                  {isDarkMode && (
                    <StaticImage height={35} src={`../../images/tramita-text-dark.svg`} alt={`Tramita Software SL`} />
                  )}
                </div>
                <div>
                  <p className={"text-lg"}>Tramita Software SL</p>
                  <p>{intl.formatMessage({ defaultMessage: "Desarrollando la administración pública del mañana" })}</p>
                </div>
              </div>
            </aside>

            {/* Nav Items */}
            <div className="flex flex-col lg:flex-row justify-end space-y-6 lg:space-y-0 lg:space-x-16">
              <nav className="flex flex-col items-start">
                <span className="footer-title mb-2">{intl.formatMessage({ defaultMessage: "Apartados" })}</span>
                <div className="flex flex-col space-y-2">
                  <Link to={`/${locale}/`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Inicio" })}
                  </Link>
                  <Link to={`/${locale}/services`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Servicios" })}
                  </Link>
                  {/*<Link to={`/${locale}/blog`} className="link link-hover">*/}
                  {/*  {intl.formatMessage({ defaultMessage: "Blog" })}*/}
                  {/*</Link>*/}
                  <Link to={`/${locale}/contact`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Contacto" })}
                  </Link>
                </div>
              </nav>

              <nav className="flex flex-col items-start">
                <span className="footer-title mb-2">{intl.formatMessage({ defaultMessage: "Aplicaciones" })}</span>
                <div className="flex flex-col space-y-2">
                  <Link to={`/${locale}/apps/files`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Gestor de expedientes" })}
                  </Link>
                  <Link to={`/${locale}/apps/myadmin`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Sede electrónica" })}
                  </Link>
                  <Link to={`/${locale}/apps/sign`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Portafirmas" })}
                  </Link>
                  <Link to={`/${locale}/apps/census`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Padrón de habitantes" })}
                  </Link>
                  <Link to={`/${locale}/apps/maps`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Mapas" })}
                  </Link>
                  <Link to={`/${locale}/apps/bi`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Business Intelligence" })}
                  </Link>
                  <Link to={`/${locale}/apps/admin`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Administración" })}
                  </Link>
                  <Link to={`/${locale}/apps/portal`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Portal" })}
                  </Link>
                </div>
              </nav>

              <nav className="flex flex-col items-start">
                <span className="footer-title mb-2">{intl.formatMessage({ defaultMessage: "Empresa" })}</span>
                <div className="flex flex-col space-y-2">
                  <Link to={`/${locale}/legal`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Aviso legal" })}
                  </Link>
                  <Link to={`/${locale}/privacy`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Política de privacidad" })}
                  </Link>
                  <Link to={`/${locale}/accessibility`} className="link link-hover">
                    {intl.formatMessage({ defaultMessage: "Accesibilidad" })}
                  </Link>
                </div>
              </nav>
            </div>
          </div>

          <div className="divider" />

          <p>
            © {currentYear}{" "}
            {intl.formatMessage({ defaultMessage: "Tramita Software SL. Todos los derechos reservados." })}
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
