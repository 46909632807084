import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import { useIntl, changeLocale } from "gatsby-plugin-intl"

export const Layout = props => {
  const intl = useIntl()
  const [isModalAccepted, setIsModalAccepted] = useState<boolean>(false)
  const modalRef = useRef<any>(null)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    const isModalShown = localStorage.getItem("is-modal-shown")
    if (isModalShown !== "true") {
      const modal = modalRef.current
      if (modal) {
        modal.showModal()
        // modal.addEventListener("cancel", e => e.preventDefault())
      }
    } else {
      setIsModalAccepted(true)
    }
  }, [])

  useEffect(() => {
    const storedLang = localStorage.getItem("gatsby-intl-language")
    // console.log(storedLang)
    // console.log(intl.locale)
    if (storedLang && storedLang !== intl.locale) {
      changeLocale(storedLang)
    } else {
      changeLocale(intl.locale)
    }
  }, [])

  return (
    <>
      <dialog id="main-modal" className="modal" ref={modalRef}>
        <div className="modal-box w-full max-w-5xl md:w-6/12 px-8">
          <h1 className="font-bold text-2xl">
            {intl.formatMessage({
              defaultMessage: "Bienvenido a la web de Tramita Software",
            })}
          </h1>
          <p className="py-4">
            {intl.formatMessage({
              defaultMessage:
                "En esta web encontrarás todo el contenido relacionado con la Plataforma Tramita para las administraciones públicas y sobre nuestra empresa.",
            })}
          </p>
          <p>
            {intl.formatMessage({
              defaultMessage:
                "Somos una startup y estamos orgullosos de ello. Nos ha costado mucho trabajo llegar hasta aquí y queremos mostrártelo, por esto, aquí encontrarás contenido real de las aplicaciones y no unas simples imágenes genéricas con contenido irrelevante.",
            })}
          </p>

          <div className={"mt-6 text-center font-bold"}>
            <p>{intl.formatMessage({ defaultMessage: "¿Te gustaría entrar?" })}</p>
          </div>
          <div className="modal-action justify-center">
            <form method="dialog">
              <div className="flex flex-row gap-4 md:flex-row">
                <a className="btn" href={"https://www.duckduckgo.com"}>
                  {intl.formatMessage({ defaultMessage: "No, no me interesa" })}
                </a>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setIsModalAccepted(true)
                    localStorage.setItem("is-modal-shown", "true")
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Sí, quiero entrar" })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </dialog>

      <div {...(!isModalAccepted && { className: "blur-md" })}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
