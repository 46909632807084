import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { changeLocale, useIntl } from "gatsby-plugin-intl"
import { useTheme } from "../hooks/useTheme"

const Header = ({ siteTitle = `` }: { siteTitle: string }) => {
  const location = useLocation()
  const { locale } = useIntl()
  const { isDarkMode, toggleTheme } = useTheme()
  const intl = useIntl()

  // Ref to track the <details> element
  const detailsRef = useRef<HTMLDetailsElement | null>(null)

  // Event handler to close the <details> menu when clicking outside or on an item
  const handleClickOutside = (event: MouseEvent) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target as Node)) {
      detailsRef.current.removeAttribute("open")
    }
  }

  const closeMenu = () => {
    detailsRef.current?.removeAttribute("open")
  }

  // Attach event listener to detect clicks outside the <details> menu
  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <div className={"sticky top-0 z-50 bg-base-100 border-b-2 border-b-base-200"}>
      <div className="container mx-auto max-w-screen-xl px-4">
        <div className={"navbar"}>
          <div className="navbar-start">
            <div className="dropdown">
              <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
              >
                <li>
                  <Link to={`/${locale}/`} lang={locale}>
                    {intl.formatMessage({ defaultMessage: "Inicio" })}
                  </Link>
                </li>
                <li>
                  <span>{intl.formatMessage({ defaultMessage: "Apps" })}</span>
                  <ul className="p-2">
                    <li>
                      <Link to={`/${locale}/apps/files`}>
                        {intl.formatMessage({ defaultMessage: "Gestor de expedientes" })}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/${locale}/apps/myadmin`}>
                        {intl.formatMessage({ defaultMessage: "Sede electrónica" })}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/${locale}/apps/sign`}>{intl.formatMessage({ defaultMessage: "Portafirmas" })}</Link>
                    </li>
                    <li>
                      <Link to={`/${locale}/apps/census`}>
                        {intl.formatMessage({ defaultMessage: "Padrón de habitantes" })}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/${locale}/apps/maps`}>{intl.formatMessage({ defaultMessage: "Mapas" })}</Link>
                    </li>
                    <li>
                      <Link to={`/${locale}/apps/bi`}>
                        {intl.formatMessage({ defaultMessage: "Business intelligence" })}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/${locale}/apps/admin`}>
                        {intl.formatMessage({ defaultMessage: "Administración" })}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/${locale}/apps/portal`}>{intl.formatMessage({ defaultMessage: "Portal" })}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={`/${locale}/services`}>{intl.formatMessage({ defaultMessage: "Servicios" })}</Link>
                </li>
                <li>
                  <Link to={`/${locale}/contact`}>{intl.formatMessage({ defaultMessage: "Contacto" })}</Link>
                </li>
              </ul>
            </div>
            <Link to={`/${locale}/`}>
              <div className={"flex flex-row items-center justify-center"}>
                {!isDarkMode && (
                  <StaticImage height={25} src={`../../images/tramita-text-light.svg`} alt={`Tramita Software SL`} />
                )}
                {isDarkMode && (
                  <StaticImage height={25} src={`../../images/tramita-text-dark.svg`} alt={`Tramita Software SL`} />
                )}
              </div>
            </Link>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal menu-md px-1">
              <li>
                <Link to={`/${locale}/`} activeClassName="active">
                  {intl.formatMessage({ defaultMessage: "Inicio" })}
                </Link>
              </li>
              <li>
                <details className={"custom-details"} ref={detailsRef}>
                  <summary>{intl.formatMessage({ defaultMessage: "Apps" })}</summary>
                  <ul className="p-2">
                    <li>
                      <Link
                        to={`/${locale}/apps/files`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Gestor de expedientes" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${locale}/apps/myadmin`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Sede electrónica" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                          <div className="badge bg-orange-600 border-orange-600 text-base-100">mobile</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${locale}/apps/sign`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Portafirmas" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                          <div className="badge bg-orange-600 border-orange-600 text-base-100">mobile</div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${locale}/apps/census`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Padrón de habitantes" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${locale}/apps/maps`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Mapas" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${locale}/apps/bi`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Business intelligence" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${locale}/apps/admin`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Administración" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${locale}/apps/portal`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Portal" })}</span>
                          <span className="badge bg-cyan-600 border-cyan-600 text-base-100">web</span>
                        </div>
                      </Link>
                    </li>
                    <li className={"disabled btn-disabled"}>
                      <Link to={`/${locale}/apps/accounting`} onClick={closeMenu} className={"whitespace-nowrap"}>
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Contabilidad" })}</span>
                          <span className="badge bg-gray-600 border-gray-600 text-base-100">2025</span>
                        </div>
                      </Link>
                    </li>
                    <li className={"disabled btn-disabled"}>
                      <Link
                        to={`/${locale}/apps/tax`}
                        onClick={closeMenu}
                        className={"whitespace-nowrap"}
                        activeClassName="active"
                      >
                        <div className={"flex flex-row gap-2"}>
                          <span>{intl.formatMessage({ defaultMessage: "Gestión tributaria" })}</span>
                          <span className="badge bg-gray-600 border-gray-600 text-base-100">2025</span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <Link to={`/${locale}/services`} onClick={closeMenu} activeClassName="active">
                  {intl.formatMessage({ defaultMessage: "Servicios" })}
                </Link>
              </li>
              <li>
                <Link to={`/${locale}/contact`} onClick={closeMenu} activeClassName="active">
                  {intl.formatMessage({ defaultMessage: "Contacto" })}
                </Link>
              </li>
            </ul>
          </div>

          {/* Botón de cambio de tema */}
          <div className="navbar-end mr-3 flex gap-2">
            <label className="swap swap-rotate">
              {/* Checkbox oculto que controla el estado */}
              <input type="checkbox" onChange={toggleTheme} checked={isDarkMode} aria-label={"Toggle theme"} />

              {/* Icono del sol (mostrar si está en modo oscuro) */}
              <svg className={"swap-on h-6 w-6 fill-current"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
              </svg>

              {/* Icono de la luna (mostrar si está en modo claro) */}
              <svg className={"swap-off h-6 w-6 fill-current"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
              </svg>
            </label>

            {/* Botón de cambio idioma */}
            <div title={intl.formatMessage({ defaultMessage: "Cambiar idioma" })} className="dropdown dropdown-end">
              <div tabIndex={0} role="button" className="btn btn-ghost" aria-label="Language">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-5 w-5">
                  <path
                    fillRule="evenodd"
                    d="M11 5a.75.75 0 0 1 .688.452l3.25 7.5a.75.75 0 1 1-1.376.596L12.89 12H9.109l-.67 1.548a.75.75 0 1 1-1.377-.596l3.25-7.5A.75.75 0 0 1 11 5Zm-1.24 5.5h2.48L11 7.636 9.76 10.5ZM5 1a.75.75 0 0 1 .75.75v1.261a25.27 25.27 0 0 1 2.598.211.75.75 0 1 1-.2 1.487c-.22-.03-.44-.056-.662-.08A12.939 12.939 0 0 1 5.92 8.058c.237.304.488.595.752.873a.75.75 0 0 1-1.086 1.035A13.075 13.075 0 0 1 5 9.307a13.068 13.068 0 0 1-2.841 2.546.75.75 0 0 1-.827-1.252A11.566 11.566 0 0 0 4.08 8.057a12.991 12.991 0 0 1-.554-.938.75.75 0 1 1 1.323-.707c.049.09.099.181.15.271.388-.68.708-1.405.952-2.164a23.941 23.941 0 0 0-4.1.19.75.75 0 0 1-.2-1.487c.853-.114 1.72-.185 2.598-.211V1.75A.75.75 0 0 1 5 1Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div
                tabIndex={0}
                className="dropdown-content bg-base-200 text-base-content rounded-box top-px mt-16 max-h-[calc(100vh-10rem)] w-56 overflow-y-auto border border-white/5 shadow-2xl outline outline-1 outline-black/5"
              >
                <ul className="menu menu-sm gap-1">
                  <li>
                    <button
                      onClick={() => changeLocale(`es`)}
                      className={location.pathname.startsWith(`/es`) ? "active" : ""}
                    >
                      <span className="badge badge-sm badge-outline !pl-1.5 !pr-1 pt-px font-mono !text-[.6rem] font-bold tracking-widest opacity-50">
                        ES
                      </span>
                      <span className="font-[sans-serif]">Castellano</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => changeLocale(`ca`)}
                      className={location.pathname.startsWith(`/ca`) ? "active" : ""}
                    >
                      <span className="badge badge-sm badge-outline !pl-1.5 !pr-1 pt-px font-mono !text-[.6rem] font-bold tracking-widest opacity-50">
                        CA
                      </span>
                      <span className="font-[sans-serif]">Català</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => changeLocale(`gl`)}
                      className={location.pathname.startsWith(`/gl`) ? "active" : ""}
                    >
                      <span className="badge badge-sm badge-outline !pl-1.5 !pr-1 pt-px font-mono !text-[.6rem] font-bold tracking-widest opacity-50">
                        GA
                      </span>
                      <span className="font-[sans-serif]">Galego</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => changeLocale(`eu`)}
                      className={location.pathname.startsWith(`/eu`) ? "active" : ""}
                    >
                      <span className="badge badge-sm badge-outline !pl-1.5 !pr-1 pt-px font-mono !text-[.6rem] font-bold tracking-widest opacity-50">
                        EU
                      </span>
                      <span className="font-[sans-serif]">Euskera</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => changeLocale(`oc`)}
                      className={location.pathname.startsWith(`/oc`) ? "active" : ""}
                    >
                      <span className="badge badge-sm badge-outline !pl-1.5 !pr-1 pt-px font-mono !text-[.6rem] font-bold tracking-widest opacity-50">
                        OC
                      </span>
                      <span className="font-[sans-serif]">Aranès</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => changeLocale(`en`)}
                      className={location.pathname.startsWith(`/en`) ? "active" : ""}
                    >
                      <span className="badge badge-sm badge-outline !pl-1.5 !pr-1 pt-px font-mono !text-[.6rem] font-bold tracking-widest opacity-50">
                        EN
                      </span>
                      <span className="font-[sans-serif]">English</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => changeLocale(`fr`)}
                      className={location.pathname.startsWith(`/fr`) ? "active" : ""}
                    >
                      <span className="badge badge-sm badge-outline !pl-1.5 !pr-1 pt-px font-mono !text-[.6rem] font-bold tracking-widest opacity-50">
                        FR
                      </span>
                      <span className="font-[sans-serif]">Français</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
